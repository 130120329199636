.campus {
    margin: 80px auto;
    width: 90%;
    text-align: center;
    max-width: 1200px;
} 

.galary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.galary img {
    height: 15rem;
    width: 15rem;
    border-radius: 10px;
    object-fit: cover;
    margin: 10px;
}

.btn svg {
    font-size: 1.4rem;
    position: relative;
    top: 0.4rem;
    margin-left: 0.4rem;
}

/* Tablet Styles */
@media screen and (max-width: 768px) {
    .campus {
        margin: 60px auto;
    }

    .galary {
        justify-content: center;
    }

    .galary img {
        height: 12rem;
        width: 12rem;
    }
}

/* Mobile Styles */
@media screen and (max-width: 480px) {
    .campus {
        margin: 40px auto;
    }

    .galary {
        flex-direction: column;
        align-items: center;
    }

    .galary img {
        height: 10rem;
        width: 10rem;
        margin: 10px 0;
    }

    .btn {
        font-size: 0.9rem;
    }

    .btn svg {
        font-size: 1.2rem;
    }
}