.donation {
    padding: 40px 20px;
    background-color: #f0f0f5;
    text-align: center;
  }
  
  .donation-overview h3 {
    font-size: 28px;
    color: #212ea0;
    margin-bottom: 10px;
  }
  
  .donation-overview p {
    font-size: 18px;
    color: #676767;
    margin-bottom: 30px;
  }
  
  .donation-options {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .donation-box {
    flex: 1 1 300px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .donation-box h4 {
    font-size: 24px;
    color: #212ea0;
    margin-bottom: 10px;
  }
  
  .donation-box p {
    font-size: 16px;
    color: #676767;
  }
  
  .donation-box:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 900px) {
    .donation-options {
      flex-direction: column;
      align-items: center;
    }
  
    .donation-box {
      margin-bottom: 20px;
      width: 100%;
      max-width: 500px;
    }
  }
  
  @media (max-width: 600px) {
    .donation-overview h3 {
      font-size: 24px;
    }
  
    .donation-overview p {
      font-size: 16px;
    }
  
    .donation-box h4 {
      font-size: 22px;
    }
  
    .donation-box p {
      font-size: 14px;
    }
  }
  