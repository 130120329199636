.mission {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    margin: 50px auto;
    max-width: 1200px;
    border-radius: 10px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
  }
  
  .mission-left, .mission-right {
    flex: 1;
    padding: 20px;
  }
  
  .mission-img {
    width: 100%;
    border-radius: 10px;
  }
  
  .mission-right h3 {
    font-size: 24px;
    color: #212ea0;
    margin-bottom: 10px;
  }
  
  .mission-right h2 {
    font-size: 32px;
    color: #1a237e;
    margin-bottom: 20px;
  }
  
  .mission-right p {
    font-size: 16px;
    color: #676767;
    margin-bottom: 15px;
  }
  
  /* Responsive Design */
  @media (max-width: 900px) {
    .mission {
      flex-direction: column;
      padding: 20px;
    }
  
    .mission-left, .mission-right {
      padding: 10px;
      margin-bottom: 20px;
    }
  
    .mission-img {
      width: 100%;
    }
  }
  