.testimonials {
  margin: 80px auto;
  padding: 0 20px;
  max-width: 1000px;
  text-align: center;
}

.section-title {
  font-size: 32px;
  color: #212ea0;
  margin-bottom: 40px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.testimonial-card {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  border: 3px solid #212ea0;
}

.user-info h3 {
  margin: 0;
  font-size: 18px;
  color: #212ea0;
}

.user-info span {
  font-size: 14px;
  color: #676767;
}

.testimonial-card p {
  font-size: 15px;
  line-height: 1.6;
  color: #676767;
}

/* Tablet Styles */
@media (max-width: 768px) {
  .testimonials {
      margin: 60px auto;
  }

  .section-title {
      font-size: 28px;
      margin-bottom: 30px;
  }

  .testimonials-grid {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }

  .testimonial-card {
      padding: 20px;
  }

  .user-info h3 {
      font-size: 16px;
  }

  .testimonial-card p {
      font-size: 14px;
  }
}

/* Mobile Styles */
@media (max-width: 480px) {
  .testimonials {
      margin: 40px auto;
      padding: 0 15px;
  }

  .section-title {
      font-size: 24px;
      margin-bottom: 25px;
  }

  .testimonials-grid {
      grid-template-columns: 1fr;
  }

  .testimonial-card {
      padding: 15px;
  }

  .user-info img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
  }

  .user-info h3 {
      font-size: 15px;
  }

  .testimonial-card p {
      font-size: 13px;
  }
}