.about {
    margin: 100px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
}

.about-left {
    flex-basis: 40%;
    position: relative;
}

.about-right {
    flex-basis: 56%;
}

.about-img {
    width: 100%;
    max-width: 90%;
    border-radius: 10px;
}

.about h3 {
    font-weight: 600;
    font-size: 16px;
    color: #212ea0;
}

.about h2 {
    font-size: 32px;
    color: #000f38;
    margin: 10px 0;
    max-width: 400px;
}

.about p {
    color: #676767;
    margin-bottom: 15px;
}

/* Tablet Styles */
@media screen and (max-width: 768px) {
    .about {
        flex-direction: column;
        margin: 50px auto;
    }

    .about-left, .about-right {
        flex-basis: 100%;
    }

    .about-left {
        margin-bottom: 30px;
    }

    .about-img {
        max-width: 100%;
    }

    .about h2 {
        font-size: 28px;
    }
}

/* Mobile Styles */
@media screen and (max-width: 480px) {
    .about {
        margin: 30px auto;
    }

    .about h3 {
        font-size: 14px;
    }

    .about h2 {
        font-size: 24px;
    }

    .about p {
        font-size: 14px;
    }
}