.footer {
    background-color: #f0f0f5; /* Light background color */
    color: #676767; /* Text color */
    padding: 20px;
    text-align: center;
    border-top: 1px solid #dcdcdc; /* Light border for separation */
    
  }
  
  .footer p {
    margin: 10px 0;
    font-size: 14px;
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer li {
    display: inline;
    margin: 0 10px;
  }
  
  .footer a {
    color: #212ea0; /* Link color */
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
    color: #1a237e; /* Darker color on hover */
  }
  