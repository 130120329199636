/* Container for the entire Why Donation section */
.why-donation {
    padding: 40px 20px;
    background-color: #f0f0f5;
    text-align: center;
  }
  
  .why-donation-content {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .why-donation h2 {
    font-size: 32px;
    color: #212ea0;
    margin-bottom: 20px;
  }
  
  .why-donation p {
    font-size: 18px;
    color: #676767;
    margin-bottom: 30px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 350px;
    text-align: left;
  }
  
  .card h3 {
    font-size: 24px;
    color: #212ea0;
    margin-bottom: 15px;
  }
  
  .card ul {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
    color: #676767;
  }
  
  .card li {
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 900px) {
    .why-donation {
      padding: 30px 15px;
    }
  
    .why-donation h2 {
      font-size: 28px;
    }
  
    .why-donation p {
      font-size: 16px;
    }
  
    .card h3 {
      font-size: 22px;
    }
  
    .card ul, .card p {
      font-size: 16px;
    }
  }
  
  @media (max-width: 600px) {
    .why-donation {
      padding: 20px 10px;
    }
  
    .why-donation h2 {
      font-size: 24px;
    }
  
    .why-donation p {
      font-size: 14px;
    }
  
    .card {
      width: 100%;
      max-width: 100%;
    }
  
    .card h3 {
      font-size: 20px;
    }
  
    .card ul, .card p {
      font-size: 14px;
    }
  }
  