.hero {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(8,0,58,0.7), rgba(8,0,58,0.7)), url('../../assets/cover.jpg');
    background-size: cover;
    background-position: center;
    color: white; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.hero-text {
    text-align: center;
    max-width: 800px;
}

.hero-text h1 {
    font-size: 60px;
    font-weight: 600;
    margin-bottom: 20px;
}

.hero-text p {
    max-width: 700px;
    margin: 0 auto 20px;
    line-height: 1.4;
}



/* Tablet styles */
@media screen and (max-width: 768px) {
    .hero-text h1 {
        font-size: 48px;
    }

    .hero-text p {
        font-size: 16px;
    }
}

/* Mobile styles */
@media screen and (max-width: 480px) {
    .hero-text h1 {
        font-size: 36px;
    }

    .hero-text p {
        font-size: 14px;
    }

    .btn {
        padding: 10px 20px;
        font-size: 14px;
    }
}